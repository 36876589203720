import './Header.scss';

const Header = () => {
    return (
        <div className={`header-container`}>
                NajjarMtg.
        </div>
    )
}

export default Header;